import { ColumnConfig } from './settings';

export type AuthMethod = 'username_password' | 'pincode' | 'saml' | 'oauth' | 'tableau_rest_api' | 'none';
export type OAuthSettings = {
  key: string;
  name: string;
  provider: 'google' | 'microsoft' | 'auth0' | 'okta' | 'salesforce';
};
export type SamlSettings = {
  key: string;
  name: string;
};
export type AuthMethodRow = {
  value: string;
  label: string;
};

export type AppSettings = {
  license: {
    expires_at: string;
    modules: string[];
    max_users: number;
    user_count: number;
    user_spots: number;
  };
  settings: {
    auth_method: AuthMethod[];
    oauth?: OAuthSettings[];
    saml?: SamlSettings[];
  };
};

export interface WriteBackExtreme {
  connectionID: string;
  schemaID: string;
  primaryKeyColumn: string;
  primaryKeySlug: string;
  schemaName: string;
  url: string;
  username: {
    datasheet: string;
    column: string;
  } | null;
  comments: WBEComments;
  audit: WBEAudit;
  columnConfig: WBEConfigMap;
  columnProperties: WBEColumnMap;
}

export type WBEConfigMap = { [key: string]: ColumnConfig | undefined };
export type WBEColumnMap = { [key: string]: WBEColumnProperties | undefined };
// This type is for the finalised combined column config
export type WBECombinedColumnConfig = ColumnConfig & WBEColumnProperties;

export type WBEColumnProperties = {
  editable: boolean;
  visible: boolean;
  shouldMerge: boolean;
  fieldName: string;
  origin: 'worksheet' | 'writebackextreme';
  dataType: STColumnType;
  index: string;
  field: string;
  precision: number | undefined;
  options: { [key: string]: string } | undefined;
};

export type WBEColumn = Column & WBEColumnProperties;

export interface WBEComments {
  schemaID: string;
  schemaName: string;
  connectionID: string;
  primaryKeySlug: string;
  primaryKeyColumn: string;
  enabled: boolean;
  showIndicator: boolean;
  color: {
    resolved: string;
    unresolved: string;
  };
}

export type Comment = {
  id: string;
  row_id: string;
  column_id: string;
  comment: string;
  user_id: number;
  resolved: boolean;
  created_at: string;
  created_by: string;
  updated_at: string;
  updated_by: string | null;
  parent_id: string | null;
  relatives: string[];
};

export type CommentWithReplies = Comment & { children?: Comment[] };

export type WBEAudit = {
  enabled: boolean;
  color: {
    created: string;
    updated: string;
    deleted: string;
  };
};

export type Audit = {
  id: number;
  user_id: number;
  auditable: string;
  auditable_id: string; // row_id
  schema_key: string;
  status: 'CREATED' | 'UPDATED' | 'DELETED';
  changes: Record<string, any>;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  created_at_human: string;
  username: string;
};

export type BulkAudits = Audit & {
  bulkAudits: Audit[];
};

export type PostComment = Pick<Comment, 'row_id' | 'column_id' | 'comment' | 'resolved' | 'parent_id' | 'created_by'>;

export type PutComment = Pick<Comment, 'id' | 'comment' | 'resolved' | 'updated_by'>;

export type WBEColumnType = 'string' | 'content' | 'number' | 'boolean' | 'select' | 'datetime';
export type STColumnType = 'string' | 'content' | 'select' | 'number' | 'float' | 'boolean' | 'date' | 'datetime';

export type WritebackNewColumn = {
  label: string;
  type: 'number' | 'string' | 'content' | 'float';
  precision?: number;
};

export type Column = {
  created_at: string;
  key: string; // UUID
  label: string;
  method: 'uuid' | 'integer' | 'string' | 'text' | 'decimal' | 'boolean' | 'date' | 'datetimeTz';
  name: string;
  nullable: boolean;
  options: { [key: string]: string } | undefined;
  order: number;
  precision?: number;
  primary: boolean | undefined;
  schema_key: string;
  type: WBEColumnType;
  updated_at: string;
  show_time: boolean;
};

export type Schema = {
  key: string;
  label: string;
  table: string;
  primary_keys: string[];
  fields: Column[];
  addon_style: null;
  conner_or_create: 'new';
  connection: string;
  created_at: string;
  description: string;
  options: any[];
  parenting: null;
  updated_at: string;
  use_rls: boolean;
};

export type UserPermission = {
  schema_key: string;
  permissions: number[];
};

export type Permission = {
  created_at: string;
  deleted_at: null;
  display_name: string;
  id: number;
  module_id: 'dataadd_form' | 'datahelper_form' | 'dataadd_collaboration';
  name: string;
  section: null;
  updated_at: string;
};

export type Connection = {
  default_casting: 'lowercase' | 'uppercase';
  groups_count: number;
  driver: 'mysql';
  key: string;
  label: string;
};

export type WritebackAppResponse = {
  schemas: Schema[];
  connections: Connection[];
  permissions: Permission[];
};

export type AddSchemaResponse = {
  code: 'CREATED';
  status: boolean;
  data: {
    primary_key: string; // sluggified primary key (Order ID -> order_i_d)
    schema_id: string;
  };
};

export type WritebackDataResponse = {
  data: Record<string, string | number>[];
  count: number;
  table: string;
  total_pages: number;
  current_page: number;
  to: number;
  from: number;
  per_page: number;
  total: number;
};

export type UserInfoResponse = {
  data: UserInfo;
  permissions: UserPermission[];
  token: string;
};

export type WritebackInputStyle = 'string' | 'number' | 'content' | 'float';

export type WBEUser = {
  id: number;
  created_at: string;
  deleted_at: string;
  updated_at: string;
  is_admin: boolean;
  is_blocked: boolean;
  display_name: string;
  tableau_id: string;
  username: string;
  same_user_id: string;
  salty: string;
};

export type UserInfo = {
  display_name: string;
  username: string;
  id: number;
  roles: object;
  is_admin: boolean;
  expires_at: string;
  groups: number[];
};

export const authMethodNameMap: Record<AuthMethod, string> = {
  username_password: 'Username and password',
  pincode: 'Pincode',
  saml: 'SAML',
  oauth: 'OAuth',
  tableau_rest_api: 'Tableau Sync',
  none: 'None',
};
