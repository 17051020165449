export type ValidationRule = {
  validate: (value: string) => boolean;
  errorMessage: string;
};

export const isEmail = (): ValidationRule => ({
  validate: (value: string) => !value || /\S+@\S+\.\S+/.test(value),
  errorMessage: 'Invalid email format',
});

export const isRequired = (errorMessage?: string, length = 1): ValidationRule => ({
  validate: (value: string) => value.length >= length,
  errorMessage: errorMessage || 'This field is required',
});

export const validateDbNamingConvention = (fieldName): ValidationRule => ({
  validate: (value: string) => {
    const startsWithNumberOrSpace = /^[0-9 ]/.test(value);
    return value.length >= 3 && !startsWithNumberOrSpace;
  },
  errorMessage: fieldName + ' must start with a letter and be at least 3 chars long.',
});

export const validateHttpsUrl = (): ValidationRule => ({
  validate: (value: string) => {
    const httpsPattern = /^https:\/\/([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?::\d{1,5})?$/i;
    return httpsPattern.test(value);
  },
  errorMessage:
    '"Invalid URL: Please enter a valid HTTPS URL without any path. Example: https://mydomain.com or https://mydomain.com:9090"',
});
