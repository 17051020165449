import { DataTable } from '@/settings';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(timezone);

export function utcToLocalTime(time: string): string {
  return dayjs.utc(time).tz(dayjs.tz.guess()).fromNow();
}
export function checkUniqueColumnKeys(summaryData: DataTable, primaryKey: string): boolean {
  let rowCount = summaryData?.data.length < 1000 ? summaryData?.data.length : 1000;
  let uniqueKeys = new Set();
  let column = summaryData?.columns.findIndex((column) => column.fieldName === primaryKey);

  if (column === -1 || column === undefined) {
    return false;
  }

  for (let i = 0; i < rowCount; i++) {
    let row = summaryData.data[i];
    let value = row[column].value;

    if (uniqueKeys.has(value)) {
      return false;
    }

    uniqueKeys.add(value);
  }
  return true;
}

export function getUniqueColumnValues(summaryData: DataTable, fieldName: string): { [key: string]: string } {
  let rowCount = summaryData?.data.length < 10000 ? summaryData?.data.length : 10000;
  let column = summaryData?.columns.findIndex((column) => column.fieldName === fieldName);
  if (column === -1 || column === undefined) {
    return {};
  }

  let uniqueKeys = new Set<string>();
  for (let i = 0; i < rowCount; i++) {
    let row = summaryData.data[i];
    let value = row[column].value;

    uniqueKeys.add(value);
  }

  return (
    Array.from(uniqueKeys).reduce((acc, key) => {
      acc[key] = key;
      return acc;
    }, {}) ?? {}
  );
}
