import { Column, GridApi, CellRange, RowNode, IRowNode } from 'ag-grid-community';

type RangeSelectedNodesCallback = (params: { rowNode: RowNode; columns: Array<Column> }) => void;
type SelectedNodesCallback = (rowNode: IRowNode) => void;
type Options = {
  allowSingleCellSelection?: boolean;
};
/**
 * Call a function for every range selected row node.
 * Note: this may call the callback multiple times for the
 * same rowNode as multiple selections may overlap.
 *
 * @param {{ allowSingleCellSelection?: boolean }} options
 */
export function forEachRangeSelectedNodes(
  api: GridApi,
  callback: RangeSelectedNodesCallback,
  options: Options = { allowSingleCellSelection: true },
  cellRanges?: CellRange[] | null
) {
  let rowModel = api.getModel();

  if (!cellRanges) {
    cellRanges = api.getCellRanges() || [];
  }

  if (options.allowSingleCellSelection === false && cellRangesAreMultipleCells(cellRanges) === false) {
    // when deselecting a row using the checkbox a single cell range selection
    // remains. this prevents us from considering that as a selected rowNode.
    return;
  }

  for (let range of cellRanges) {
    if (!range.startRow || !range.endRow) continue; // make ts happy

    let startRow = Math.min(range.startRow.rowIndex, range.endRow.rowIndex);
    let endRow = Math.max(range.startRow.rowIndex, range.endRow.rowIndex);

    for (let rowIndex = startRow; rowIndex <= endRow; rowIndex++) {
      let rowNode = rowModel.getRow(rowIndex);
      if (!rowNode || range.columns === null) continue;
      callback({ rowNode, columns: range.columns });
    }
  }
}

export function forEachSelectedNodes(api: GridApi, callback: SelectedNodesCallback) {
  let rowSelections = api.getSelectedNodes();
  for (let rowNode of rowSelections) {
    callback(rowNode);
  }

  forEachRangeSelectedNodes(api, ({ rowNode }) => callback(rowNode), {
    allowSingleCellSelection: false,
  });
}

function cellRangesAreMultipleCells(cellRanges: Array<CellRange>) {
  return (
    // there are multiple range selections (could overlap on one cell but w/e)
    cellRanges.length > 1 ||
    // or there is one range selection
    (cellRanges.length === 1 &&
      // and there are multiple columns selected
      ((cellRanges[0].columns && cellRanges[0].columns.length > 1) ||
        // or there are multiple rows selected
        (cellRanges[0].startRow &&
          cellRanges[0].endRow &&
          cellRanges[0].startRow.rowIndex !== cellRanges[0].endRow.rowIndex)))
  );
}
