import { showToast } from '@/components/toast';

export function handleError(callback: () => void, title: string = 'Error') {
  try {
    callback();
  } catch (error) {
    const msg = error instanceof Error ? error.message : undefined;
    showToast('Error while adding reply', 'error', msg);
  }
}

export async function getErrorFromResponse(response: Response) {
  if (!response.ok) {
    let body = await response.json();
    let message = body.message || body.msg || body.reason_phrase || body;
    throw new Error(message);
  }
}
