export default class BiMap {
  forwardMap: Map<string, string>;
  reverseMap: Map<string, string>;
  constructor() {
    this.forwardMap = new Map();
    this.reverseMap = new Map();
  }

  set(key: string, value: string) {
    if (this.forwardMap.has(key)) {
      this.reverseMap.delete(this.forwardMap.get(key));
    }
    if (this.reverseMap.has(value)) {
      this.forwardMap.delete(this.reverseMap.get(value));
    }
    this.forwardMap.set(key, value);
    this.reverseMap.set(value, key);
  }

  get(key: string) {
    return this.forwardMap.get(key);
  }

  getKey(value: string) {
    return this.reverseMap.get(value);
  }

  print() {
    for (const [key, value] of this.forwardMap) {
      console.log(`${key} => ${value}`);
    }
  }

  getValuesForwardMap() {
    return Array.from(this.forwardMap.values());
  }

  clear() {
    this.forwardMap.clear();
    this.reverseMap.clear();
  }
}
