import {
  Connection,
  Permission,
  Schema,
  UserInfo,
  UserInfoResponse,
  UserPermission,
  WBEColumnMap,
  WBECombinedColumnConfig,
  WBEConfigMap,
  WriteBackExtreme,
} from '@/types/writeback';
import BiMap from '@/utils/BiMap';
import {
  CellEditingStartedEvent,
  CellEditingStoppedEvent,
  CellRange,
  Column,
  GridApi,
  RowNode,
  ValueSetterParams,
} from 'ag-grid-community';
import { forEachRangeSelectedNodes } from '../utils/grid-selection';
import { create } from 'zustand';
import { getAllRows, getAppInfo, refreshToken, setBulkRows } from './api';
import { queryClient } from './queryClient';
import { TabsType } from '.';
import { refreshDataSources } from '@/grid/mapping/utils';

// Local store
interface WBEState extends WriteBackExtreme {
  token: string;
  schemas: Schema[];
  activeSchema: Schema | undefined; // TODO: check
  connections: Connection[];
  permissions: Permission[];
  nameMap: BiMap;
  userInfo: UserInfo | null;
  commentPermissions: number[];
  dataPermissions: number[];
  unmergedRows: any[];
  selectedTab: TabsType;
  selectedCellRanges: CellRange[] | null;
  setUrl: (url: string) => void;
  setUserPermissions: (permissions: UserPermission[]) => void;
  removeToken: () => void;
  setActiveSchema: (schema: Schema) => void;
  setSchemas: (schemas: Schema[]) => void;
  setNewSchema: (
    schemaID: string,
    schemaName: string,
    primaryKeySlug: string,
    primaryKeyColumn: string,
    connectionID: string
  ) => void;
  setNewCommentSchema: (
    schemaID: string,
    schemaName: string,
    primaryKeySlug: string,
    primaryKeyColumn: string,
    connectionID: string
  ) => void;
  setConnections: (connections: Connection[]) => void;
  setPermissions: (permissions: Permission[]) => void;
  setPrimaryColumn: (column: string) => void;
  resetSchema: () => void;
  resetCommentSchema: () => void;

  isValid: () => boolean;
  setInitialSettings: (wbe: WriteBackExtreme) => void;
  initializeWBE: () => Promise<void>;
  refreshToken: (token: string) => Promise<void>;
  cellEditingStarted: (params: CellEditingStartedEvent) => void;
  valueSetter: (params: ValueSetterParams) => Promise<boolean> | boolean;
  getColumns: (columnsConfig: WBEConfigMap, columnsProperties: WBEColumnMap) => WBECombinedColumnConfig[];
  mergeRows: (rows: any[]) => Promise<any[]>;
  bulkValueSetter: (params: CellEditingStoppedEvent) => Promise<boolean> | boolean;
  writeData: (api: GridApi, column: Column, data: RowNode, newValue) => Promise<boolean>;
  setUsernameWorksheet: (userNameWorksheet: string, userNameColumn: string) => void;
  setUserInfo: (userInfo: UserInfoResponse) => void;
  amIAllowedToDo: (permission: string, type: string) => boolean;
  isCreatedByMe: (user: number) => boolean;
}

export const useWBEStore = create<WBEState>((set, get) => ({
  url: '',
  connectionID: '',
  schemaID: '',
  primaryKeyColumn: '',
  primaryKeySlug: '',
  schemaName: '',
  username: null,
  nameMap: new BiMap(),
  userInfo: null,
  comments: {
    schemaID: '',
    schemaName: '',
    connectionID: '',
    primaryKeySlug: '',
    primaryKeyColumn: '',
    enabled: false,
    showIndicator: true,
    color: {
      resolved: '#2BA7DF',
      unresolved: '#FF0079',
    },
  },
  audit: {
    enabled: true,
    color: {
      created: '#2BA7DF',
      updated: '#3B474F',
      deleted: '#FF0079',
    },
  },
  token: '',
  schemas: [],
  activeSchema: undefined,
  connections: [],
  permissions: [],
  commentPermissions: [],
  dataPermissions: [],
  columnConfig: {},
  columnProperties: {},
  selectedTab: 'Comments',
  unmergedRows: [],
  selectedCellRanges: null,
  setUrl: (url: string) => set({ url }),
  setUserPermissions: (permissions: UserPermission[]) => {
    const state = get();

    // get permissions for the two schemas (add data and comments)
    if (state.schemaID) {
      set({ dataPermissions: getPermission(state.schemaID) });
    }
    if (state.comments.schemaID) {
      set({ commentPermissions: getPermission(state.comments.schemaID) });
    }

    function getPermission(id) {
      return permissions.find((p) => p.schema_key === id)?.permissions;
    }
  },
  removeToken: () => {
    set({ token: '' });
    localStorage.removeItem('wbe_token');
  },
  setPrimaryColumn: (column: string) => set({ primaryKeyColumn: column }),
  setUserInfo: (userInfo: UserInfoResponse) => {
    set({ userInfo: userInfo.data, token: userInfo.token });
    localStorage.setItem('wbe_token', userInfo.token);

    const setUserPermissions = get().setUserPermissions;
    setUserPermissions(userInfo.permissions);
  },
  setActiveSchema: (schema: Schema) => set({ activeSchema: schema }),
  setSchemas: (schemas: Schema[]) => set({ schemas }),
  setConnections: (connections: Connection[]) => set({ connections }),
  setPermissions: (permissions: Permission[]) => set({ permissions }),
  setUsernameWorksheet: (userNameWorksheet: string, userNameColumn: string) =>
    set({ username: { datasheet: userNameWorksheet, column: userNameColumn } }),
  setNewSchema: (schemaID, schemaName, primaryKeySlug, primaryKeyColumn, connectionID) =>
    set({
      schemaID,
      schemaName,
      primaryKeySlug,
      primaryKeyColumn,
      connectionID,
      columnProperties: {},
    }),
  setNewCommentSchema: (schemaID, schemaName, primaryKeySlug, primaryKeyColumn, connectionID) =>
    set((state) => ({
      comments: {
        ...state.comments,
        schemaID,
        schemaName,
        primaryKeySlug,
        primaryKeyColumn,
        connectionID,
      },
    })),

  resetSchema: () =>
    set({
      schemaID: '',
      schemaName: '',
      primaryKeyColumn: '',
      primaryKeySlug: '',
      connectionID: '',
    }),
  resetCommentSchema: () =>
    set((state) => ({
      comments: {
        ...state.comments,
        schemaID: '',
        schemaName: '',
        primaryKeySlug: '',
        primaryKeyColumn: '',
        connectionID: '',
      },
    })),

  // WriteBackExtreme Functions

  isValid: () => {
    const state = get();
    return !!state.url && !!state.token && !!state.schemaID && !!state.connectionID;
  },
  initializeWBE: async () => {
    const state = get();
    if (!state.url || !state.token) {
      return;
    }

    try {
      const appInfo = await getAppInfo(state.url, state.token);
      set({
        connections: appInfo.connections,
        schemas: appInfo.schemas,
        permissions: appInfo.permissions,
      });
    } catch (error) {
      console.error('Failed to initialize WBE:', error);
    }
  },
  refreshToken: async (token: string) => {
    const state = get();
    try {
      const result = await refreshToken(state.url, token);

      const setUserInfo = get().setUserInfo;
      setUserInfo(result);
      const initializeWBE = get().initializeWBE;
      await initializeWBE();
    } catch (error) {
      console.error('Failed to refresh token:', error);
      set({ token });
    }
  },
  getColumns: (columnsConfig: WBEConfigMap, columnsProperties: WBEColumnMap) => {
    const state = get();
    if (!state.url || !state.token || !state.schemaID) {
      return [];
    }

    if (state.schemas?.length === 0) {
      return [];
    }
    const activeSchema = state.schemas.find((schema) => schema.key === state.schemaID);
    if (!activeSchema) {
      return [];
    }

    const amIAllowedToDo = get().amIAllowedToDo;

    const fields = activeSchema.fields;
    const columns: WBECombinedColumnConfig[] = [];
    for (const field of fields) {
      state.nameMap?.set(field.label, field.name);

      const columnProperties = columnsProperties?.[field.name] ?? null;
      if (!columnProperties || (!columnProperties.visible && !columnProperties.shouldMerge)) {
        continue;
      }
      if (!field.primary) {
        let columnConfig = (columnsConfig[field.name] as WBECombinedColumnConfig) ?? {};

        columnConfig.index = field.name;
        columnConfig.field = field.label;
        columnConfig.fieldName = columnConfig.fieldName ?? field.label;
        columnConfig.editable = amIAllowedToDo('write', 'data') ? columnProperties.editable : false;
        columnConfig.visible = columnProperties.visible;
        columnConfig.origin = 'writebackextreme';
        columnConfig.dataType = columnProperties.dataType;
        columnConfig.options = field.options ?? undefined;

        columns.push(columnConfig);
      }
    }

    return columns;
  },
  mergeRows: async (rows: any[]) => {
    const state = get();
    if (!state.url || !state.token || !state.schemaID) {
      return rows;
    }

    // when there is no active schema, return empty array
    const activeSchema = state.schemas.find((schema) => schema.key === state.schemaID);
    if (!activeSchema) {
      return rows;
    }

    try {
      const wbeRows = await getAllRows(state.url, state.token, state.schemaID);
      if (!wbeRows || !wbeRows.length) {
        return rows;
      }

      const { primaryKeyColumn, primaryKeySlug } = state;

      /**
       * wbeRowsMap holds all data from WBE that should be merged per primary key
       * The keys in the value object is the friendly name of the column
       * Example:{
          "key": "CA-2021-100867",
          "value": {
              "String field": "String value",
              "my number field": 666,
          }
        }
       */
      const wbeRowsMap = new Map();

      const propertiesToSkip = ['created_at', 'updated_at', 'user_id', 'relates'];
      for (let wbeRow of wbeRows) {
        let primaryKeyValue = wbeRow[primaryKeySlug];

        const row = {};
        for (let key in wbeRow) {
          const columnName = state.nameMap.reverseMap.get(key) ?? key;

          if (propertiesToSkip.includes(key) || key === primaryKeySlug) {
            continue; // filter out default columns and primary key
          }

          const columnProperties = state.columnProperties[key];
          if (
            !columnProperties ||
            (columnProperties.origin === 'worksheet' && !columnProperties.shouldMerge) ||
            wbeRow[key] === null
          ) {
            continue; // don't add column when it's not configured or should not be merged
          }
          row[columnName] = wbeRow[key];
        }
        wbeRowsMap.set(primaryKeyValue, row);
      }

      // build array of merged row data
      const mergedRows = rows.map((row) => {
        const primaryKeyValue = row[primaryKeyColumn];
        let wbeRow = {};
        for (let [key, value] of wbeRowsMap) {
          // change to double equals to allow for type number to string comparison
          if (key == primaryKeyValue) {
            wbeRow = value;
          }
        }

        if (!wbeRow) {
          return row;
        }

        return {
          ...row,
          ...wbeRow,
        };
      });

      set({ unmergedRows: rows });

      return mergedRows;
    } catch (error) {
      console.error('Failed to merge rows:', error);
      return rows;
    }
  },
  setInitialSettings: async (wbe: WriteBackExtreme) => {
    set({
      connectionID: wbe.connectionID,
      schemaID: wbe.schemaID,
      primaryKeyColumn: wbe.primaryKeyColumn,
      primaryKeySlug: wbe.primaryKeySlug,
      schemaName: wbe.schemaName,
      username: wbe.username,
      comments: wbe.comments,
      audit: wbe.audit,
      url: wbe.url,
      columnConfig: wbe.columnConfig,
      columnProperties: wbe.columnProperties,
    });

    const localToken = localStorage.getItem('wbe_token');
    if (localToken) {
      const refreshToken = get().refreshToken;
      await refreshToken(localToken);
    }
  },
  cellEditingStarted: (params: CellEditingStartedEvent) => {
    set({
      selectedCellRanges: params.api.getCellRanges()?.map(
        (range) =>
          ({
            startRow: range.startRow,
            endRow: range.endRow,
            columns: [...range.columns], // create a shallow copy of columns
          }) as CellRange
      ),
    });
  },
  valueSetter: (params: ValueSetterParams) => {
    if (params.newValue !== null) {
      return false;
    }

    const { writeData } = get();
    const oldValue = params.node?.data[params.column.getColId()];
    return writeData(params.api, params.column, oldValue, null);
  },
  bulkValueSetter: (params: CellEditingStoppedEvent) => {
    const oldValue = params.node.data[params.column.getColId()];

    const { writeData } = get();
    return writeData(params.api, params.column, oldValue, params.newValue);
  },
  writeData: async (api: GridApi, column: Column, data: RowNode, newValue: any | null) => {
    const state = get();
    if (!state.url || !state.token || !state.schemaID || !state.primaryKeySlug || !state.primaryKeyColumn) {
      return false;
    }

    let updatedColumns = new Set<Column>();
    let updatedNodes = new Set<RowNode>();
    let bulkData = {};
    // loop trough every selected range
    forEachRangeSelectedNodes(
      api,
      ({ columns, rowNode }) => {
        columns.forEach((selectedColumn) => {
          // only change columns that thave the same column type
          if (selectedColumn.getColDef().cellDataType === column.getColDef().cellDataType) {
            const primaryValue = rowNode.data[state.primaryKeyColumn];
            const colId = selectedColumn.getColId();
            const technicalName = state.nameMap.get(colId) ?? colId;

            if (['number', 'float'].includes(column.getColDef().cellDataType ?? '') && newValue > 2147483647) {
              newValue = 0; // we do not allow to exceed max int
            }

            if (technicalName) {
              bulkData[primaryValue] = {
                ...bulkData[primaryValue],
                [technicalName]: newValue,
              };
            } else {
              console.error('Could not find technical name for ' + colId);
            }

            updatedColumns.add(selectedColumn);
            if (rowNode.data[colId] !== newValue) {
              if (newValue === null && rowNode.rowIndex != null && rowNode.rowIndex != undefined) {
                rowNode.data[colId] = state.unmergedRows[rowNode.rowIndex][colId];
              } else {
                rowNode.data[colId] = newValue;
              }
            }
          }

          updatedNodes.add(rowNode);
        });
      },
      { allowSingleCellSelection: true },
      state.selectedCellRanges // use the selected range that we have saved on cellEditingStarted
    );

    set({
      selectedCellRanges: null,
    });
    api.refreshCells({
      rowNodes: Array.from(updatedNodes),
      force: true,
    });
    api.refreshClientSideRowModel();

    const bulkUpdates = Object.entries(bulkData).map(([primary_key, value]) => ({
      [state.primaryKeySlug]: primary_key,
      ...value,
    }));

    try {
      await setBulkRows(state.url, state.token, state.schemaID, bulkUpdates);
      queryClient.invalidateQueries({ queryKey: ['audits'] });

      document.getElementById('reloadButton')!.style.display = 'block';
      document.getElementById('reloadButton')!.addEventListener('click', refreshDataSources);
    } catch (error) {
      console.error('Failed to write data:', error);

      alert('Cannot save changes ' + error);
      return false;
    }

    return true;
  },
  isCreatedByMe(user: number): boolean {
    const state = get();
    return state.userInfo?.id == user;
  },
  amIAllowedToDo(permission: string, type: string): boolean {
    const state = get();

    const userPermissions = type === 'comment' ? state.commentPermissions : state.dataPermissions;
    if (!userPermissions) {
      return false;
    }
    const mappedPermissions = userPermissions.map((p) => {
      const permission = state.permissions.find((per) => per.id == p);
      if (permission) {
        return permission.name && permission.section ? `${permission.name}-${permission.section}` : permission.name;
      }
      return undefined;
    });

    return mappedPermissions.includes(permission);
  },
}));

export const wbeStore = useWBEStore;
