import { Button } from '@/components/ui/button';
import { OAuthSettings } from '@/types/writeback';
import Spinner from '@/components/spinner';

type OAuthProps = {
  oauthMethods: OAuthSettings[] | undefined;
  startAuthentication: (key: string) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
};

const OAuth = ({ oauthMethods, startAuthentication, loading, setLoading }: OAuthProps) => {
  const handleAuthentication = (key: string) => {
    setLoading(true);
    startAuthentication(key);
  };

  return loading ? (
    <center>
      Currently there is a process running in the browser.
      <Spinner />
    </center>
  ) : (
    <div className="mt-5 flex flex-col gap-2">
      {oauthMethods?.map((method) => (
        <Button
          key={method.key}
          className="cursor-pointer border p-1"
          onClick={() => handleAuthentication(method.key)}>
          <div>{method.name}</div>
        </Button>
      ))}
    </div>
  );
};

export default OAuth;
