import { showToast } from '@/components/toast';
import { Button } from '@/components/ui/button';
import { InputWithLabel } from '@/components/ui/input';
import { getUsername } from '@/utils/tableau';
import { loginToWriteBackExtreme } from '@/writeback/api';
import { useWBEStore } from '@/writeback/store';
import { useEffect, useState } from 'react';

const Pincode = ({}) => {
  const { setUserInfo, url, username } = useWBEStore();

  const [usernameValue, setUsernameValue] = useState('');
  const [pincode, setPincode] = useState('');
  const [blocked, setBlocked] = useState(false);

  useEffect(() => {
    const fetchUsername = async () => {
      const result = await getUsername(username);

      if (!result || result == '') {
        setBlocked(true);
      }
      setUsernameValue(result);
    };

    fetchUsername();
  }, []);

  async function login() {
    try {
      await loginToWriteBackExtreme(url, usernameValue, pincode, 'pincode', setUserInfo);
    } catch (error) {
      showToast('' + error, 'error');
      console.error('Error while logging in:', error);
    }
  }
  return blocked ? (
    <div className="mt-5">Pincode is not available because there is no username sheet configured.</div>
  ) : (
    <div>
      <div className="mt-5">
        <InputWithLabel
          id="pincode"
          label="Pincode"
          value={pincode}
          type="pincode"
          onChange={(e) => setPincode(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              login();
            }
          }}
        />
        Authenticating with user:
        <br />
        <b>{usernameValue}</b>
        <div className="mt-5 flex items-center justify-between">
          <Button onClick={login}>Login</Button>
        </div>
      </div>
    </div>
  );
};

export default Pincode;
