import { useEffect, useState } from 'react';
import { useWBEStore } from '../../../../writeback/store';
import { getUsername } from '@/utils/tableau';
import { loginToWriteBackExtreme } from '@/writeback/api';
import { showToast } from '@/components/toast';

const NoAuth = ({}) => {
  const { setUserInfo, url, username } = useWBEStore();
  const [blocked, setBlocked] = useState(false);

  useEffect(() => {
    const startLoginProcedure = async () => {
      const usernameValue = await getUsername(username);
      if (!usernameValue || usernameValue == '') {
        setBlocked(true);
      }
      try {
        await loginToWriteBackExtreme(url, usernameValue, '', 'noAuth', setUserInfo);
      } catch (error) {
        showToast('' + error, 'error');
        console.error('Error while logging in:', error);
      }
    };
    if (username) {
      startLoginProcedure();
    } else {
      console.error('Cannot start login procedure because username config is missing');
    }
  }, []);

  return blocked ? (
    <div className="mt-5">No Authentication is not available because there is no username sheet configured.</div>
  ) : (
    <div>Starting authentication...</div>
  );
};

export default NoAuth;
