import Select, { ActionMeta } from 'react-select';

type ComboboxProps<Option> = {
  placeholder?: string;
  value?: any;
  defaultValue?: Option;
  onChange?: (value: any, actionMeta: ActionMeta<Option>) => void;
  options: Option[];
  isDisabled?: boolean;
  isClearable?: boolean;
  isMulti?: boolean;
  isSearchable?: boolean;
  className?: string;
  isLoading?: boolean;
  maxMenuHeight?: number;
  getOptionLabel?: (option: Option) => string;
  getOptionValue?: (option: Option) => string;
  isOptionDisabled?: (option: Option) => boolean;
};

const Combobox = <Option,>({
  placeholder,
  value,
  defaultValue,
  onChange,
  options,
  isDisabled,
  isClearable,
  isMulti = false,
  isSearchable,
  className,
  isLoading,
  maxMenuHeight,
  getOptionLabel,
  getOptionValue,
  isOptionDisabled,
}: ComboboxProps<Option>) => {
  return (
    <div className="mb-[7px]">
      <Select
        placeholder={placeholder ?? 'Select...'}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        options={options}
        isDisabled={isDisabled}
        isClearable={isClearable}
        isMulti={isMulti}
        isSearchable={isSearchable}
        className={className}
        isLoading={isLoading}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        isOptionDisabled={isOptionDisabled}
        styles={customStyles}
        maxMenuHeight={maxMenuHeight}
      />
    </div>
  );
};

const customStyles = {
  control: (base, state) => ({
    ...base,
    backgroundColor: `hsl(var(--background))`,
    borderColor: state.isFocused ? `hsl(var(--primary))` : `#D1D5DB`,
    '&:hover': {
      borderColor: state.isFocused ? `hsl(var(--primary))` : `#D1D5DB`,
    },
    boxShadow: 'none',
    borderRadius: `3px`,
    fontFamily: `Karla`,
    fontSize: `1rem`,
    lineHeight: `1.75rem`,
    color: state.isDisabled ? 'gray' : 'inherit',
    cursor: state.isDisabled ? 'not-allowed' : 'default',
    opacity: state.isDisabled ? 0.5 : 1,
  }),
  menu: (base) => ({
    ...base,
    backgroundColor: `hsl(var(--popover))`,
  }),
  option: (base, state) => ({
    ...base,
    fontWeight: state.isSelected && 'bold',
    backgroundColor: state.isFocused ? `hsl(var(--accent))` : `hsl(var(--background))`,
    color: state.isDisabled
      ? `hsl(var(--grayed-foreground))`
      : state.isSelected
        ? `hsl(var(--primary))`
        : `hsl(var(--foreground))`,
    '&:hover': {
      backgroundColor: `none`,
    },
    '&:active': {
      backgroundColor: `hsl(var(--accent))`,
    },
    fontFamily: `Karla`,
    cursor: state.isDisabled ? 'not-allowed' : 'default',
  }),
  placeholder: (base, state) => ({
    ...base,
    color: state.isDisabled ? 'gray' : 'black',
    minWidth: 'none',
    fontFamily: `Karla`,
  }),
  singleValue: (base, state) => ({
    ...base,
    color: state.isDisabled ? 'gray' : `black`,
  }),
};

export default Combobox;
